/* ---------------------------------
Theme
--------------------------------- */

import { ThemeOverride } from "@chakra-ui/react";

const customTheme: ThemeOverride = {
  colors: {
    accent: {
      "01": "#2F79D9",
      "02": "#000000",
      "03": "#3676BB",
    },
    hover: {
      "01": "#0050c1",
      "02": "#cce0fc",
    },
    // Palettes made with https://materialpalettes.com/
    scb: {
      50: "#ffe7e6",
      100: "#ffc8b8",
      200: "#ffa28b",
      300: "#ff7b5c",
      400: "#ff5838",
      500: "#ff2b12",
      600: "#ff220d",
      700: "#fa1606",
      800: "#EC0000",
      900: "#d50000",
    },
    tim: {
      50: "#e7eaf8",
      100: "#c3caed",
      200: "#9aa7e1",
      300: "#7185d5",
      400: "#4f6acc",
      500: "#274fc3",
      600: "#2047b9",
      700: "#123dad",
      800: "#0033a1",
      900: "#00208d",
    },
    hcbe: {
      "50": "#e4f3fe",
      "100": "#bddffe",
      "200": "#94ccfe",
      "300": "#6bb8fc",
      "400": "#4da8fc",
      "500": "#3699fb",
      "600": "#338bec",
      "700": "#2f79d9",
      "800": "#2b67c7",
      "900": "#2549a7",
    },
    hyundai: {
      "50": "#e5e9ef",
      "100": "#bcc7d9",
      "200": "#92a3bf",
      "300": "#6980a6",
      "400": "#486695",
      "500": "#214d86",
      "600": "#1a467d",
      "700": "#0f3d72",
      "800": "#073366",
      "900": "#01234f",
    },
    kia: {
      "50": "#ecf9ff",
      "100": "#e4f1ff",
      "200": "#dae7f9",
      "300": "#c9d6e7",
      "400": "#a5b2c2",
      "500": "#8591a1",
      "600": "#5d6979",
      "700": "#4a5665",
      "800": "#2c3845",
      "900": "#0b1824",
    },
    utils: {
      black: "#1A1A1A",
      gray: {
        "01": "#EFEFEF",
        "02": "#666666",
        "03": "#3676BB",
        "04": "#8D8D8F",
        "05": "#F6F7FB",
        "06": "#777",
      },
      white: "#FFF",
      offWhite: "#F6F7FB",
      transparent: "transparent",
    },
    strokes: {
      "01": "rgba(255,255,255,.4)",
      "02": "#ffffff26",
      "03": "#dcdcdc",
    },
  },
  fonts: {
    body: "Arial, sans-serif, system-ui",
    heading: "You-And-I-modern, sans-serif, system-ui",
    mono: "Menlo, monospace",
  },
  sizes: {
    container: {
      grid: "1344px",
      md: "780px",
      lg: "1200px",
      xl: "1400px",
    },
  },
  components: {
    Heading: {
      variants: {
        normal: {
          fonts: "Arial, sans-serif, system-ui",
        },
      },
    },
  },
};

export default customTheme;
