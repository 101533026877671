export let ANALYTICS_ID: string | undefined;

switch (process.env.GATSBY_ENV) {
  case "development":
    break;
  case "acceptance":
    ANALYTICS_ID = "G-BZW0WT5PNV";
    break;
  case "production":
    ANALYTICS_ID = "G-X5WBCXX1B0";
    break;
  default:
    console.error("Env variable GATSBY_ENV must be set!");
}
