/* ---------------------------------
utils
--------------------------------- */

import slugify from "slugify";

// makeSlug
export function makeSlug(items: string[], prefix?: string): string | undefined {
  if (!items?.length || items.some(item => typeof item !== "string")) {
    return;
  }

  const slugParts = items
    .map(item =>
      slugify(item, {
        lower: true,
        strict: true,
      })
    )
    .join()
    .replace(/,/g, "/");

  return `${prefix ? "/" + prefix : ""}/${slugParts}/`;
}

// formatBreadcrumbLabel
export function formatBreadcrumbLabel(pathname: string): string {
  // const replacer = /[\-|\/]/g;

  return (
    pathname
      ?.split?.("-")
      // .map(word => word.replace(replacer, ""))
      ?.map?.(word => word?.charAt?.(0).toUpperCase?.() + word?.substr?.(1))
      ?.join?.(" ")
  );
}

export const waitForGlobal = (key: any, callback: () => void) => {
  const interval = setInterval(function () {
    if (window[key]) {
      clearInterval(interval);
      callback();
    }
  }, 200);
  setTimeout(() => {
    clearInterval(interval);
  }, 10000);
};
