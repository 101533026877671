exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-accessibilita-index-tsx": () => import("./../../../src/pages/accessibilita/index.tsx" /* webpackChunkName: "component---src-pages-accessibilita-index-tsx" */),
  "component---src-pages-area-dealer-index-tsx": () => import("./../../../src/pages/area-dealer/index.tsx" /* webpackChunkName: "component---src-pages-area-dealer-index-tsx" */),
  "component---src-pages-area-riservata-index-tsx": () => import("./../../../src/pages/area-riservata/index.tsx" /* webpackChunkName: "component---src-pages-area-riservata-index-tsx" */),
  "component---src-pages-area-trasparenza-index-tsx": () => import("./../../../src/pages/area-trasparenza/index.tsx" /* webpackChunkName: "component---src-pages-area-trasparenza-index-tsx" */),
  "component---src-pages-chiedi-esperto-index-tsx": () => import("./../../../src/pages/chiedi-esperto/index.tsx" /* webpackChunkName: "component---src-pages-chiedi-esperto-index-tsx" */),
  "component---src-pages-contatti-index-tsx": () => import("./../../../src/pages/contatti/index.tsx" /* webpackChunkName: "component---src-pages-contatti-index-tsx" */),
  "component---src-pages-corporate-chi-siamo-index-tsx": () => import("./../../../src/pages/corporate/chi-siamo/index.tsx" /* webpackChunkName: "component---src-pages-corporate-chi-siamo-index-tsx" */),
  "component---src-pages-corporate-corporate-governance-index-tsx": () => import("./../../../src/pages/corporate/corporate-governance/index.tsx" /* webpackChunkName: "component---src-pages-corporate-corporate-governance-index-tsx" */),
  "component---src-pages-corporate-index-tsx": () => import("./../../../src/pages/corporate/index.tsx" /* webpackChunkName: "component---src-pages-corporate-index-tsx" */),
  "component---src-pages-corporate-investor-relations-index-tsx": () => import("./../../../src/pages/corporate/investor-relations/index.tsx" /* webpackChunkName: "component---src-pages-corporate-investor-relations-index-tsx" */),
  "component---src-pages-corporate-struttura-index-tsx": () => import("./../../../src/pages/corporate/struttura/index.tsx" /* webpackChunkName: "component---src-pages-corporate-struttura-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-per-il-pubblico-credito-consapevole-index-tsx": () => import("./../../../src/pages/info-per-il-pubblico/credito-consapevole/index.tsx" /* webpackChunkName: "component---src-pages-info-per-il-pubblico-credito-consapevole-index-tsx" */),
  "component---src-pages-info-per-il-pubblico-inadempimenti-abf-index-tsx": () => import("./../../../src/pages/info-per-il-pubblico/inadempimenti-abf/index.tsx" /* webpackChunkName: "component---src-pages-info-per-il-pubblico-inadempimenti-abf-index-tsx" */),
  "component---src-pages-info-per-il-pubblico-index-tsx": () => import("./../../../src/pages/info-per-il-pubblico/index.tsx" /* webpackChunkName: "component---src-pages-info-per-il-pubblico-index-tsx" */),
  "component---src-pages-info-per-il-pubblico-reclami-index-tsx": () => import("./../../../src/pages/info-per-il-pubblico/reclami/index.tsx" /* webpackChunkName: "component---src-pages-info-per-il-pubblico-reclami-index-tsx" */),
  "component---src-pages-info-per-il-pubblico-sicurezza-in-rete-index-tsx": () => import("./../../../src/pages/info-per-il-pubblico/sicurezza-in-rete/index.tsx" /* webpackChunkName: "component---src-pages-info-per-il-pubblico-sicurezza-in-rete-index-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-note-legali-index-tsx": () => import("./../../../src/pages/note-legali/index.tsx" /* webpackChunkName: "component---src-pages-note-legali-index-tsx" */),
  "component---src-pages-privacy-cookies-index-tsx": () => import("./../../../src/pages/privacy-cookies/index.tsx" /* webpackChunkName: "component---src-pages-privacy-cookies-index-tsx" */),
  "component---src-pages-privacy-cookies-informativa-cookies-index-tsx": () => import("./../../../src/pages/privacy-cookies/informativa-cookies/index.tsx" /* webpackChunkName: "component---src-pages-privacy-cookies-informativa-cookies-index-tsx" */),
  "component---src-pages-psd-2-index-tsx": () => import("./../../../src/pages/.psd2/index.tsx" /* webpackChunkName: "component---src-pages-psd-2-index-tsx" */),
  "component---src-templates-news-detail-tsx": () => import("./../../../src/templates/NewsDetail.tsx" /* webpackChunkName: "component---src-templates-news-detail-tsx" */)
}

