export let ENABLE_ONETRUST = true;
export let ONETRUST_DOMAIN_SCRIPT = "";
export let ONETRUST_SRC = "";

/**
 * Domains used by OneTrust,
 * this variable is used for development purpose only,
 * because Mirage mock server needs to bypass them
 */
export const ONETRUST_DOMAINS: string[] = [
  "https://cdn.cookielaw.org",
  "https://privacyportal-eu.onetrust.com",
  "https://geolocation.onetrust.com",
];

/*
 * OneTrust cookies category
 * Doc: https://app-eu.onetrust.com/cookies/categorizations?tab=Categories
 */

export enum cookiesCategory {
  STRICTLY_NECESSARY = "C0001",
  PERFORMANCE = "C0002",
  FUNCTIONAL = "C0003",
  TARGETING = "C0004",
  SOCIAL_MEDIA = "C0005",
}

/*
 * OneTrust Configuration switch
 */

switch (process.env.GATSBY_ENV) {
  case "development":
    console.log("OneTrust is using development configuration");
    ENABLE_ONETRUST = false;
    ONETRUST_DOMAIN_SCRIPT = "ccdfef19-2469-4561-95f8-bc3c60e061a2-test";
    ONETRUST_SRC = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    break;
  case "acceptance":
    console.log("OneTrust is using acceptance configuration");
    ONETRUST_DOMAIN_SCRIPT = "ccdfef19-2469-4561-95f8-bc3c60e061a2-test";
    ONETRUST_SRC = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    break;
  case "production":
    ONETRUST_DOMAIN_SCRIPT = "ccdfef19-2469-4561-95f8-bc3c60e061a2";
    ONETRUST_SRC = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    break;
  default:
    console.error("Env variable GATSBY_ENV must be set!");
}
