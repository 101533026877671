/**
 * This file includes third party snippets, not properly typed
 */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { ANALYTICS_ID } from "../../constants/analytics";
import { useCheckConsentsOneTrust } from "../cookies/lib/useCheckConsentsOneTrust";
import { cookiesCategory } from "../../constants/oneTrust";
import { waitForGlobal } from "../../utils";

declare global {
  interface Window {
    dataLayer: unknown[];
    gtag: () => void;
  }
}

function gtag() {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}

function gTagFn() {
  console.log("GOOGLE ANALYTICS ACTIVATED");
  window.dataLayer = window.dataLayer || [];
  gtag("js", new Date());
  gtag("config", ANALYTICS_ID);
}

/**
 * Send a virtual page view to google analytics
 */
export const analyticsPageView = (pagePath: string, title?: string): void => {
  waitForGlobal("gtag", () => {
    console.log("analyticsPageView", pagePath);

    // using "set" as suggested here: https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
    gtag("set", "page_path", pagePath);

    // sending event as suggested here: https://developers.google.com/analytics/devguides/collection/gtagjs/pages#page_view_event
    gtag("event", "page_view", {
      page_title: title || pagePath,
      page_location: location.origin + pagePath,
      page_path: pagePath,
    });
  });
};

/**
 * Adds google analytics tag, based on global ANALYTICS_ID
 */
export const Analytics: React.FC = () => {
  const isAllowedByOneTrust = useCheckConsentsOneTrust(
    cookiesCategory.PERFORMANCE
  );

  useEffect(() => {
    if (ANALYTICS_ID && isAllowedByOneTrust) {
      window.gtag = gtag;
      gTagFn();
    }
  }, [isAllowedByOneTrust]);

  if (!ANALYTICS_ID) {
    return null;
  }

  if (!isAllowedByOneTrust) {
    console.log("Don't load GTM tag");
    return null;
  }

  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${ANALYTICS_ID}`}
        className="optanon-category-C0002"
      ></script>
    </Helmet>
  );
};
